<template>
  <div>
    <div class="query">
      <span>结算日期：</span>
      <date-range-field class="no-error" v-model="query.range" :f="f"></date-range-field>
      <a-button style="margin-left: 10px;" type="primary" @click="handleQuery">查询</a-button>
      <a-button style="margin-left: 10px;" @click="handleReset">重置</a-button>
    </div>

    <div style="font-size: 12px; color: #999; padding-bottom: 5px;">已选择订单：</div>
    <a-table size="small"
      :data-source="selectedItems"
      row-key="orderItemId"
      :columns="columns"
      style="margin-bottom: 10px;"
      :pagination="false"
    ></a-table>

    <a-table size="small"
      :data-source="data"
      :loading="loading"
      row-key="orderItemId"
      :columns="columns"
      :row-selection="rowSelection"
      :pagination="false"
    ></a-table>
  </div>
</template>

<script>
import { computed, watch, onMounted, reactive, ref } from '@vue/composition-api'
import { DateRangeField as DRF } from './report'
import { capital } from '@/api'
import DateRangeField from './DateRangeField'
import NP from 'number-precision'
export default {
  name: 'InvoiceOrder',
  components: { DateRangeField },
  props: ['value', 'updateTotal', 'type'],
  emits: ['input'],
  setup (props, { emit }) {
    const query = reactive({
      range: []
    })

    onMounted(async () => {
      await getData()
      console.log(props.type)
      if (rowSelection.selectRowKeys.length === 0) {
        rowSelection.selectRowKeys = data.value.map(x => x.orderItemId)
      }
    })

    const data = ref([])
    const loading = ref(false)
    async function getData () {
      loading.value = true
      const res = props.type ? await capital.getListTradeCommissionOrder(query.range.length ? {
        settleBeginTime: query.range[0].format('YYYY-MM-DD 00:00:00'),
        settleEndTime: query.range[1].format('YYYY-MM-DD 23:59:59')
      } : {}) : await capital.getInvoiceServiceOrder(query.range.length ? {
        settleBeginTime: query.range[0].format('YYYY-MM-DD 00:00:00'),
        settleEndTime: query.range[1].format('YYYY-MM-DD 23:59:59')
      } : {})
      loading.value = false
      data.value = res.data
    }

    function handleQuery () {
      getData()
    }

    function handleReset () {
      query.range = []
      handleQuery()
    }

    const columns = [
      {
        dataIndex: 'settleTime',
        title: '结算时间'
      },
      {
        dataIndex: 'orderNo',
        title: '订单编号',
      },
      {
        dataIndex: 'invoiceAmount',
        title: '开票金额'
      },
    ]

    const selectedKeys = computed({
      get: () => props.value,
      set: v => emit('input', v)
    })
    const selectedItems = ref([])
    const rowSelection = ({
      selectRowKeys: computed(() => selectedKeys),
      onChange: (x, y) => {
        selectedKeys.value = x.slice()
        selectedItems.value = y.slice()
      }
    })

    watch(selectedItems, (v) => {
      props.updateTotal(v.reduce((acc, x) => NP.plus(acc, +x.invoiceAmount), 0))
    })

    return {
      query,
      f: new DRF({}),
      data,
      loading,
      handleQuery,
      handleReset,
      columns,
      rowSelection,
      selectedItems,
    }
  }
}
</script>

<style lang="css" scoped>
.query {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.no-error ::v-deep .ant-input, .no-error ::v-deep .ant-input:hover {
  border-color: #d9d9d9!important;
}
</style>
