<template>
  <div class="page-box">
    <a-form-model ref="form" layout="vertical" :model="model">
      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-model-item label="发票类型">
            <a-input disabled value="增值税电子普通发票" />
          </a-form-model-item>
          <a-form-model-item label="发票抬头" prop="invoiceTitle"
            :rules="[{ required: true, message: '请填写' }]"
          >
            <a-input :disabled="directFlag" v-model="model.invoiceTitle" placeholder="请输入"></a-input>
          </a-form-model-item>
          <a-form-model-item label="纳税人识别号" prop="taxpayerNumber"
            :rules="[{ required: true, message: '请填写' }]"
          >
            <a-input :disabled="directFlag" v-model="model.taxpayerNumber" placeholder="请输入"></a-input>
          </a-form-model-item>
          <a-form-model-item label="申请开票金额" prop="invoiceAmount">
            <a-input disabled v-model="model.invoiceAmount" placeholder="根据添加订单自动计算"></a-input>
          </a-form-model-item>
        </a-col>

        <a-col :span="12">
          <a-form-model-item label="注册地址" prop="registerAddr">
            <a-input v-model="model.registerAddr" placeholder="请输入"></a-input>
          </a-form-model-item>
          <a-form-model-item label="注册电话" prop="registerPhone">
            <a-input v-model="model.registerPhone" placeholder="可以是座机"></a-input>
          </a-form-model-item>
          <a-form-model-item label="开户银行" prop="openBank">
            <a-input v-model="model.openBank" placeholder="请输入"></a-input>
          </a-form-model-item>
          <a-form-model-item label="银行账号" prop="bankCardNo">
            <a-input v-model="model.bankCardNo" placeholder="请输入"></a-input>
          </a-form-model-item>
          <a-form-model-item label="邮箱" prop="email">
            <a-input v-model="model.email" placeholder="请输入"></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-form-model-item label="选择开票订单" prop="orderItemIds"
        :rules="[
          { required: true, message: '请选择开票订单' },
          {
            validator: (rule, value, callback) => {
              if (value.length) {
                callback()
              } else {
                callback(new Error('请选择开票订单'))
              }
            }
          }
        ]"
      >
        <invoice-order v-model="model.orderItemIds" :updateTotal="updateInvoiceAmountTotal" :type='directFlag'/>
      </a-form-model-item>
      <a-form-model-item v-if="directFlag" label="上传发票" prop="invoicePic">
        <upload-img
          :fileList="model.invoicePic ? [model.invoicePic] : []"
          @update:fileList='res=>model.invoicePic=res.length?res[0]:""'
          :max="1"
        />
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click="$closeCurrentView">取消</a-button>
        <a-button style="margin-left: 15px;" :loading="submitting" type="primary" @click="save">申请开票</a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from '@vue/composition-api'
import { capital } from '@/api'
import InvoiceOrder from './InvoiceOrder'
import { wrapAwait } from '@/utils/tools'
import { message } from 'ant-design-vue'

export default {
  name: 'PageInvoiceForm',
  components: {
    InvoiceOrder,
  },
  props: {
    directFlag: Boolean,
  },
  setup (props, { root }) {
    const model = reactive({
      invoiceTitle: props.directFlag ? '烟台新零售电子商务有限公司' : '',
      taxpayerNumber: props.directFlag ? '91370600MA3RXDE36D' : '',
      registerAddr: props.directFlag ? '烟台高新区蓝海路1号4号楼' : '',
      registerPhone: props.directFlag ? '0535-3942067' : '',
      openBank: props.directFlag ? '中国工商银行烟台高新技术产业开发区支行' : '',
      bankCardNo: props.directFlag ? '1606050609100026910' : '',
      email: props.directFlag ? '362509384@qq.com' : '',
      invoiceAmount: '',
      orderItemIds: [],
      invoicePic: '',
    })

    function updateInvoiceAmountTotal (v) {
      model.invoiceAmount = v
    }

    const form = ref(null)
    const submitting = ref(false)
    async function save () {
      const valid = await form.value.validate()
      if (!valid) return
      submitting.value = true
      const [err] = await wrapAwait((props.directFlag ? capital.createCommissionInvoice : capital.requestServiceInvoice)({ ...model }))
      submitting.value = false
      if (err) {
        message.error('提交失败')
      } else {
        message.success('提交成功')
        root.$closeCurrentView()
      }
    }

    if (!props.directFlag) {
      onMounted(() => {
        fetchHistoryInfo()
      })
    }

    async function fetchHistoryInfo () {
      const { data } = await capital.getInvoiceHistory()
      Object.assign(model, data)
    }

    return {
      model,
      save,
      form,
      submitting,
      updateInvoiceAmountTotal,
    }
  }
}
</script>

<style lang="css" scoped>

</style>
