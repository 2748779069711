<template>
  <div>
    <a-range-picker v-model="valueComputed" :placeholder="f.placeholder" />
    <a-radio-group v-model="quickRefer" button-style="solid" style="margin-left: 15px;">
      <a-radio-button value="today">
        今
      </a-radio-button>
      <a-radio-button value="yestoday">
        昨
      </a-radio-button>
      <a-radio-button value="7">
        近7天
      </a-radio-button>
      <a-radio-button value="30">
        近30天
      </a-radio-button>
    </a-radio-group>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import moment from 'moment'

export default {
  name: 'DateRangeField',
  props: ['value', 'f'],
  emits: ['input'],
  setup (props, { emit }) {
    const valueComputed = computed({
      get () {
        return props.value
      },
      set (value) {
        emit('input', value)
      }
    })

    const today = moment().startOf('day')
    const yestoday = moment().startOf('day').subtract(1, 'day')
    const seven = moment().startOf('day').subtract(1, 'week')
    const thirty = moment().startOf('day').subtract(1, 'month')
    const quickRefer = computed({
      get: () => {
        let a, b
        if ((a = valueComputed.value[0]) && (b = valueComputed.value[1])) {
          if (a.isSame(today) && b.isSame(today)) {
            return 'today'
          }
          if (a.isSame(yestoday) && b.isSame(yestoday)) {
            return 'yestoday'
          }
          if (a.isSame(seven) && b.isSame(today)) {
            return '7'
          }
          if (a.isSame(thirty) && b.isSame(today)) {
            return '30'
          }
          return ''
        } else {
          return ''
        }
      },
      set: (value) => {
        switch (value) {
          case 'today':
            valueComputed.value = [today, today]
            break
          case 'yestoday':
            valueComputed.value = [yestoday, yestoday]
            break
          case '7':
            valueComputed.value = [seven, today]
            break
          case '30':
            valueComputed.value = [thirty, today]
            break
          default: throw new Error('unknonw value')
        }
      }
    })

    return {
      valueComputed,
      quickRefer,
    }
  }
}
</script>

<style lang="css" scoped>

</style>
