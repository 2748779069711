class Field {
  constructor (options) {
    this._title = options.title
    this._key = options.key
  }

  get title () { return this._title }
  get key () { return this._key }
}

class QueryField extends Field {
  constructor (options) {
    super(options)
    this._type = options.type
    this._hidden = options.hidden
  }

  get hidden () {
    return this._hidden
  }
  get type () { return this._type }
  get initialValue () { return this._initialValue }
  get placeholder () {
    return this._placeholder
  }
}

class SelectField extends QueryField {
  constructor (options) {
    super(options)
    this._options = options.options
    this._placeholder = options.placeholder || '请选择'
    this._initialValue = options.initial || undefined
  }

  get options () {
    return this._options
  }
}

class SearchField extends QueryField {
  constructor (options) {
    super(options)
    this._search = options.search
    this._placeholder = options.placeholder || '搜索并选择'
    this._initialValue = options.initial || undefined
  }

  get search () {
    return this._search
  }
}

class InputField extends QueryField {
  constructor (options) {
    super(options)
    this._placeholder = options.placeholder || '请输入'
    this._initialValue = options.initial || ''
  }
}

export class DateRangeField extends QueryField {
  constructor (options) {
    super(options)
    this._placeholder = options.placeholder || ['开始', '结束']
    this._initialValue = options.initial || []
  }
}

class DateField extends QueryField {
  constructor (options) {
    super(options)
    this._placeholder = options.placeholder || '请选择'
    this._initialValue = options.initial || undefined
  }
}

function createQueryField (options) {
  switch (options.type) {
    case 'select': return new SelectField(options)
    case 'input': return new InputField(options)
    case 'daterange': return new DateRangeField(options)
    case 'date': return new DateField(options)
    case 'search': return new SearchField(options)
    default: throw new Error(`unknown field type ${options.type}`)
  }
}

class ResultField extends Field {
  constructor (options) {
    super(options)
    this._customRender = options.transform
    this._scopeFlag = options.scopeFlag
    this._width = options.width
    this._fixed = options.fixed
  }

  get fixed () {
    return this._fixed
  }

  get width () {
    return this._width
  }

  get customRender () {
    return this._customRender
  }

  get dataIndex () {
    return this.key
  }

  plain () {
    const result = {
      dataIndex: this.dataIndex,
      title: this.title,
      customRender: this.customRender,
      width: this.width,
      fixed: this.fixed,
    }
    if (this._scopeFlag) {
      result.scopedSlots = {
        customRender: this.key
      }
    }
    return result
  }
}

export class Report {
  /**
   *
   * @param {QueryField[]} queryFields
   * @param {ResultField[]} resultFields
   */
  constructor (queryFields, resultFields, options) {
    this._queryFields = queryFields.map(f => createQueryField(f))
    this._resultFields = resultFields.map(f => new ResultField(f))
    this._queryApi = options.queryApi
    this._exportApi = options.exportApi
    this._key = options.key
  }

  get exportApi () {
    return this._exportApi
  }

  query (params) {
    return this._queryApi({ ...params })
  }

  get key () {
    return this._key
  }

  get queryFields () {
    return this._queryFields
  }

  get resultFields () {
    return this._resultFields
  }

  get resultFieldsPlain () {
    return this._resultFields.map(f => f.plain())
  }

  get initialQuery () {
    return this._queryFields.reduce((acc, x) => ({
      ...acc,
      [x.key]: x.initialValue
    }), {})
  }
}
